#root {
  height: 100%;
}
body {
  overscroll-behavior: none;
}
.container-signup {
  max-width: 40rem;
}
.container-account-delete {
  max-width: 842px;
}
.dropdown-menu-card {
  min-width: 400px;
}
.modal-fullwidth-margin {
  margin: var(--tblr-modal-margin);
}
.dropdown-arrow-none.dropdown-toggle::after {
  display: none;
}
.sw-1 > path {
  stroke-width: 1;
}
.sw-2 > path {
  stroke-width: 2;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.avatar-rc {
  --tblr-avatar-size: 3.5rem;
}
.avatar-2 {
  --tblr-avatar-size: 2.15rem;
}
.avatar-chat {
  --tblr-avatar-size: 2.25rem;
}
.avatar-assign-vendor {
  width: 2.25rem;
  height: 2.25rem;
}
canvas {
  width: 100%;
  height: 100%;
}
.accordion-button:after {
  margin-left: 1rem;
}
.top-backdrop {
  z-index: 1055;
}
.he {
  opacity: 0.64;
  transition: opacity 0.3s;
}
.he:hover {
  opacity: 1;
}
.dropzone {
  margin: 2rem 1.5rem;
  margin-bottom: 0.25rem;
  border-style: dashed;
  border-width: 2px;
  min-height: 120px;
  height: calc(100% - 100px);
  border-color: var(--tblr-gold);
  /* background-color: rgba(var(--tblr-light-rgb),0.05); */
  color: var(--tblr-gold);
  transition: 0.3s;
  border-radius: 10px;
}
.dropzone:hover {
  background-color: rgba(var(--tblr-muted-rgb), 0.05);
  border-color: var(--tblr-primary);
  color: var(--tblr-primary);
}
.dd-w-10 {
  min-width: 10rem !important;
}
.mw-65 {
  min-width: 65px;
}
@media (min-width: 768px) {
  .navbar-expand-md .nav-item .nav-link.active:after,
  .navbar-expand-md .nav-item .nav-link.active:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.55rem;
    border: 0 var(--tblr-border-style) var(--tblr-gold);
    border-bottom-width: 2px;
  }
}
.navbar-vertical.navbar-expand-lg .navbar-collapse .navbar-nav .nav-link {
  padding-top: 11px;
  padding-bottom: 11px;
  /* margin-bottom: 10px; */
}

.navbar-vertical.navbar-expand-lg .navbar-brand {
  /* justify-content: left;
  padding-top: 15px;
  padding-left: 20px; */
}
aside .nav-link.active {
  background: var(--tblr-primary);
  border-radius: 0 !important;
  transition: all 0.3s ease;
}
aside .nav-link.active svg,
aside .nav-link.active .nav-link-title {
  color: var(--tblr-white);
}
.dd-action {
  min-width: 5rem !important;
}
.dd-action .dropdown-item {
  min-width: 5rem !important;
}
.btn {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-weight: 600;
}
.btn-normal {
  padding-top: var(--tblr-btn-padding-y);
  padding-bottom: var(--tblr-btn-padding-y);
}
.animate-fade {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.animate-fade.in {
  opacity: 1;
}
.fancybox__container {
  z-index: 1056 !important;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}
.chat-message {
  animation: fadeIn 0.5s ease-in-out;
}
.react-datepicker__triangle {
  display: none;
}
.mh-250 {
  min-height: 250px;
}
.line-clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}
.dropdown-menu-column a.dropdown-item.active {
  background: var(--tblr-primary) !important;
  color: var(--tblr-white) !important;
}
.scroll {
  max-height: 350px;
  overflow-y: auto;
}
.Toastify__toast {
  border: 1px solid;
  border-color: rgba(var(--tblr-primary-rgb), 1);
}
.Toastify__progress-bar {
  height: 2px;
}
.Toastify__progress-bar-theme--light {
  background: var(
    --tblr-primary
  ); /* Replace this with your desired progress bar color */
}
.pac-container {
  z-index: 9999;
}
.input-group > .form-control:not(:focus).is-invalid {
  z-index: 0;
}
.signup.form-check-input:checked + .form-check-label {
  color: var(--tblr-primary);
}
.mt--9 {
  margin-top: -9px;
}
.vendor:focus + .form-selectgroup-label {
  box-shadow: none;
}
.react-datepicker__close-icon::after {
  padding: 2px 5px 4px 5px !important;
}
.form-selectgroup-input:checked + .form-selectgroup-label.rc-input {
  z-index: 0;
  color: var(--tblr-white);
  background: rgba(var(--tblr-primary-rgb), 1);
  border-color: var(--tblr-primary);
}
.form-selectgroup-input:disabled + .form-selectgroup-label.rc-input {
  cursor: not-allowed;
}
.form-selectgroup-input:focus + .form-selectgroup-label.rc-input {
  z-index: 2;
  /* color: var(--tblr-white); */
  border-color: var(--tblr-primary);
  box-shadow: 0 0 0 0.25rem rgba(32, 107, 196, 0.25);
}
.form-selectgroup-input:checked + .form-selectgroup-label.rc {
  background: rgba(var(--tblr-primary-rgb), 1);
  color: var(--tblr-light);
}
.border-dashed {
  border-style: dashed !important;
}
.upload-photos-label {
  width: 100%;
  padding-top: 55px;
  padding-bottom: 56px;
}
.upload-photos-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* min-height: 310px; */
}
.upload-photos {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.upload-photos-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
@media (max-width: 576px) {
  .dropdown-menu-card {
    min-width: 325px;
  }
}
.service-block {
  width: 4rem !important;
}

.form-selectgroup-item-rc {
  flex: 0 0 auto;
  width: 110px; /* Adjust the width as needed */
  margin-right: 10px; /* Space between items */
}

.form-selectgroup-label-rc {
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  box-shadow: none !important;
}

.category-icon-container {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: rgba(27, 97, 181, 0.05);
  -webkit-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}
.form-selectgroup-input:checked
  + .form-selectgroup-label
  .category-icon-container {
  background: rgba(var(--tblr-primary-rgb), 1);
}
.form-selectgroup-input:checked + .form-selectgroup-label .category-icon {
  filter: invert(100%);
}
.category-icon {
  margin-top: auto;
}
.custom-scrollbar::-webkit-scrollbar {
  height: 4px !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  /* background-color: rgba(var(--tblr-primary-rgb), 1); */
}
.bg-green-opt {
  background-color: rgba(var(--tblr-green-rgb), 0.05);
}
.bg-primary-opt {
  background-color: rgba(var(--tblr-primary-rgb), 0.05);
}
.mvh-75 {
  min-height: calc(100vh - 200px) !important;
}
.mvh-75 .card-body {
  flex: none !important;
}
.fixed-modal-body {
  margin-right: 0 !important;
}
.task-ovelay {
  position: absolute;
  top: 100px;
  background: rgb(255, 255, 255);
  width: 100%;
  height: calc(100% - 100px);
  z-index: 111;
  /* border: 1px solid; */
}
